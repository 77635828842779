import './polyfills';
require('./styles/index.css');

const scale = document.querySelector('.scale__y');
const envelope = document.querySelector('.envelope');
if (scale && envelope) {
  setTimeout(() => {
    scale.classList.add('scale__y--animated');
    envelope.classList.add('envelope--animated');
  }, 250);
}

const submits = document.querySelectorAll('button[type="submit"]');
submits.forEach(button => {
  const form = button.closest('form');
  const msg = form.nextElementSibling;

  button.addEventListener('click', e => {
    const required = Array.from(form.querySelectorAll('input[required]'));
    const invalid = required.some(field => field.value === '');

    if (invalid) {
      e.preventDefault();
      if (msg) {
        msg.classList.remove('hidden');
        msg.innerText = 'Please complete all fields.';
      }
    } else {
      form.classList.add('hidden');
      if (msg) {
        msg.classList.remove('hidden');
        msg.innerHTML = `<strong>Thanks!</strong> We'll be in touch to schedule your demo.`;

        if (window.gtag) {
          gtag('event', 'sign_up', {
            event_category: 'engagement',
            event_label: 'Demo Form'
          });
        }
      }
    }
  });
});
